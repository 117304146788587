











































import { copyTextToClipboard } from '@/services/Copy';
import rService from '@/services/RequestService/RequestServiceSetup';

export default {
  name: 'ModalLeakKey',
  data() {
    return {
      ip_whitelist: '192.126.161.211',
      leak_key: '',
      disabled: true,
      dialog: false,
      loading: false,
    };
  },
  methods: {
    async removeKey() {
      this.leak_key = '';
      await this.save('')
      this.dialog = false;
    },
    getApiKey() {
      this.disabled = true;
      rService.get(`leak_check_api`).then((res) => {
        if (res && res.leak_key) {
          this.leak_key = res.leak_key;
        }
        this.disabled = false;
      });
    },
    async save() {
      this.loading = true;
      await rService.post(`leak_check_api`, { leak_key: this.leak_key }).then((res) => {
        if (res && res.leak_key) {
          this.leak_key = res.leak_key;
        } else {
          this.leak_key = '';
        }
        this.loading = false;
      });
    },
    copy(text, e) {
      const res = copyTextToClipboard(text);
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$copied(`<p>Copied to clipboard:<br>${text}</p>`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }
    },
    t(item) {
      return this.$vuetify.lang.t('$vuetify.' + item);
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.ModalLeak.' + item);
    },
  },
  watch: {
    dialog(val: string) {
      if (val) {
        this.getApiKey();
      }
    },
  },
};
