



















































































































































































































































import ModalLeakKey from '@/components/ModalLeakKey.vue';
import { getReferences, getRules, getTypes, Link } from '@/services/constants';
import rService from '../services/RequestService/RequestServiceSetup';
import { mapActions, mapGetters } from 'vuex';
import XLSX from 'xlsx';
import alertS from '../services/AlertService/AlertService';

export default {
  name: 'Home',
  components: {
    BreachChips: () => import('@/components/BreachComponents/Chip.vue'),
    DehashedDialog: () => import('@/components/CheckAccount/Dehashed.vue'),
    UsesLeft: () => import('@/components/CheckAccount/UsesLeft.vue'),
    DialogAlert: () => import('@/components/CheckAccount/Alert.vue'),
    SingleLeak: () => import('@/components/CheckAccount/SingleLeak.vue'),
    AlertPlans: () => import('@/components/CheckAccount/PlansAlerts.vue'),
    DialogBreach: () => import('@/components/BreachComponents/DialogBreach.vue'),
    PlanData: () => import('@/components/PlanData.vue'),
    ModalLeakKey,
  },
  data() {
    return {
      disable_settings: true,
      rowsPerPageItems: [10, 20, 30, 50],
      excel: {
        data: null,
        name: '',
      },
      discordError: '',
      telegramError: '',
      sendDiscord: false,
      sendTelegram: false,
      sendEmail: false,
      saveEntry: true,
      sortBy: 'searched',
      sortDesc: true,
      alertPlans: true,
      colorCard: 'primary',
      instantFilter: true,
      propsSearch: '1',
      absolute: true,
      lastSearch: '',
      searchItem: '',
      loadingBreaches: false,
      breachesFound: [],
      breaches: [],
      filtered: [],
      password: '',
      pagination: {},
      sourceTypes: [],
      allItems: [],
      references: this.getReferences(),
      findLine: null,
      safe: null,
      typeSelected: {
        name: String,
        value: String,
      },
      validFormSearch: false,
      disableSearchBtn: true,
      search: '',
      toSearch: '',
      searchDisabled: true,
      searchHolder: this.$vuetify.lang.t('$vuetify.Home.searchHolder'),
      loading: false,
      lastType: '',
      toSearchVal: '',
      allValues: [],
      values: [],
      searchInputValue: '',
      typeSelP: '',
      entrySearch: '',
      f: true,
    };
  },
  methods: {
    async dehash(item) {
      const hash = item.hash;
      const api = await rService.post(`dehash`, {
        hash,
      });
      if (api && api.plain) {
        item.password = api.plain;
        item.dehashed = true;
      }
    },
    scrollTopTable() {
      if (this.f) {
        this.f = false;
        return;
      }
      const tTable = this.$refs.top_table;
      if (tTable)
        this.$vuetify.goTo(tTable, {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        });
    },
    loadSearch() {
      const route = this.$route;
      if (route.params) {
        const params = route.params;
        if (params.type && params.entry) {
          this.toSearch = params.entry;
          this.typeSelected = params.type;
        } else if (params.id) {
          this.entrySearch = params.entrySearch;
          this.loadFromId(params.id, route.name);
        } else {
          this.typeSelected = params.typeSelected;
          this.toSearch = params.toSearch;
          this.values = [];
          this.allValues = [];
        }
      } else {
        this.typeSelected = '';
        this.toSearch = '';
      }
    },
    async sendUpdates() {
      await this.sendTelegramUpdate();
      await this.sendDiscordUpdate();
    },
    async checkPlatform(platform: string, prop: string, alert = false) {
      const checkApi = await rService.get(`check_bot_link/${platform}`);
      const userId = checkApi.user_id;
      console.log('CHECK', userId, this[prop]);
      if (!userId && this[prop]) {
        const route = this.$router.resolve({ name: 'botLinks' });
        if (alert) {
          const text = this.translate('linkRequest');
          const str = text.replace('<platform>', platform);
          alertS.error(`${str}<a target="_blank" href="${route.href}">${route.href}</a>`);
        }
        this[prop] = false;
      } else if (alert) {
        this.saveSettings();
      }
    },
    async sendDiscordUpdate(alert = false) {
      await this.checkPlatform('discord', 'sendDiscord', alert);
    },
    async sendTelegramUpdate(alert = false) {
      await this.checkPlatform('telegram', 'sendTelegram', alert);
    },
    showTracking() {
      const isTracking = this.$route.name == 'trackingData';
      if (isTracking) return ' - Tracking';
      else return '';
    },
    exportExcel: function () {
      const dataToExport = this.values.map((el) => {
        return {
          Line: el.line,
          Email: el.email,
          Password: el.password,
          'Last Breach': el.last_breach ? this.$moment(el.lastBreachD).format('DD/MM/YYYY') : '',
          Sources: el.sources
            .map(function (source) {
              return source.Name;
            })
            .join(', '),
        };
      });
      const data = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      let filename = `${this.typeSelP}_${this.lastSearch}`;
      if (filename.length > 30 || filename.includes('*')) {
        filename = 'downloadLeaks';
      }
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    row_classes(item) {
      if (item.searched) {
        return 'primary darken-4';
      }
      const breaches = item.sources.length;
      let toReturn = 'normal_leak';
      if (breaches > 2) {
        toReturn = 'danger_leak';
      } else if (breaches > 1) {
        toReturn = 'warning_leak';
      }
      return toReturn;
    },
    rowClick(value) {
      this.$refs.singleLeak.open(value);
    },
    ...mapActions(['setupTimer', 'destroyUserInfo', 'openSnack', 'updateUserInfo', 'setCooldown', 'openLogin']),
    loadBreaches() {
      this.loadingBreaches = true;
      this.breaches = [];
      this.values.forEach((el) => {
        el.sources.forEach((element) => {
          delete element.unknown;
          if (element.Name != 'Unknown') {
            if (this._.find(this.breaches, element) == undefined) this.breaches.push(element);
          }
        });
      });
      this.loadingBreaches = false;
    },
    checkExperimentalG() {
      const routeData = this.$router.resolve({
        name: 'experimentalCheck',
        params: {
          type: this.typeSelected,
          entry: this.toSearch,
        },
      });
      window.open(routeData.href, '_blank');
    },
    checkDehashedG() {
      const routeData = this.$router.resolve({
        name: 'dehashedCheck',
        params: {
          type: this.typeSelected,
          entry: this.toSearch,
        },
      });
      window.open(routeData.href, '_blank');
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    timeAgo(date) {
      return this.$moment(date).fromNow();
    },
    checkDehashed(item) {
      const currentType = this.typeSelP;
      const routeData = this.$router.resolve({
        name: 'dehashedCheck',
        params: {
          type: currentType,
          entry: item.email,
        },
      });
      window.open(routeData.href, '_blank');
    },
    changedSource() {
      const values = this.sourceTypes;
    },
    processColor(link: Link) {
      if (link.unknown) {
        return 'grey';
      }
      const color = link.leakCheck && !link.hbwPwned ? '#5446F8' : link.hbwPwned && !link.leakCheck ? '#3a9ac4' : link.leakCheck && link.hbwPwned ? 'red lighten-1' : 'black';
      return color;
    },
    processDisabled(link: Link) {
      return link.unknown || (link.leakCheck && !link.hbwPwned);
    },
    processDisabledRef(reference) {
      const val = reference.value;
      const selected = this.typeSelected;
      if (val == 'hibp') {
        const allowed = ['email', 'login'];
        return !allowed.includes(selected);
      }
    },
    getTypes() {
      return getTypes(this.translate);
    },
    getReferences() {
      return getReferences(this.translate);
    },
    customFilter(value, stringSearch, item) {
      /* Search parameters */
      const { findLine, sourceTypes, breachesFound, searchItem } = {
        findLine: this.findLine,
        sourceTypes: this.sourceTypes,
        breachesFound: this.breachesFound,
        searchItem: this.searchItem,
      };

      if (breachesFound && breachesFound.length) {
        const oneBrachF = breachesFound.find((el) => {
          return (
            item.sources.find((elS) => {
              return elS.Name == el;
            }) != undefined
          );
        });
        if (oneBrachF == undefined) return false;
      }
      if (sourceTypes && sourceTypes.length) {
        const itemSources = item.sources.find((el) => {
          const elLinked = el.Linked && el.Linked.unknown ? { unknown: true } : { ...el.Linked };
          return this._.find(sourceTypes, { Linked: elLinked });
        });
        if (itemSources == undefined) {
          return false;
        }
      }

      // if (findLine) return item.line == findLine;
      if (findLine && item.combo) {
        if (typeof findLine === 'string') return item.combo.indexOf(findLine) !== -1;
        else return item.combo == findLine.combo;
      }

      // return item.combo != null && searchItem != null && item.combo.indexOf(findLine) !== -1;
      return true;
    },
    resetFields(ev) {
      this.values = [];
      this.allValues = [];
      this.breaches = [];
      this.typeSelected = '';
      this.breachesFound = [];
      this.searchInputValue = '';
      this.safe = null;
      this.colorCard = 'primary';
    },
    updateFiltered(items) {
      this.filtered = [];
      if (this.$refs.tableLeaks) this.filtered = this.$refs.tableLeaks.$children[0].filteredItems;
      else this.filtered = items;
    },
    goPricing() {
      const access_token = this.$store.state.userInfo.token;
      if (!access_token) {
        this.openLogin();

        // alertS.error('You have to login first');
      } else {
        this.$router.push({ name: 'pricing' }).catch();
      }
    },
    cleanLastResult() {
      this.findLine = null;
      this.sourceTypes = [];
      this.breachesFound = [];
      this.searchItem = '';
      this.f = true;
    },
    async areYourSure() {
      this.$refs.dialogAlert.open();
    },
    async loadFromId(id, routeName) {
      try {
        if (routeName == 'trackingData') {
          this.loading = true;
          const entryObj = await rService.get(`tracking/history/${id}`);
          if (entryObj) {
            const { entry, type, result, creation_date, changes } = entryObj;
            this.typeSelected = type;
            this.toSearch = entry;
            this.typeSelP = `${this.typeSelected}`;
            this.lastSearch = `${this.toSearch}`;
            this.loadValues(result, true);
          }
          this.loading = false;
        } else if (this.userInfo && this.userInfo.platform) {
          this.loading = true;
          const entries = await rService.get(`my_entries/${this.userInfo.platform}?id=${id}`);
          if (entries.length) {
            const { entry, data, type } = entries[0];
            this.typeSelected = type;
            this.toSearch = entry;
            this.typeSelP = `${this.typeSelected}`;
            this.lastSearch = `${this.toSearch}`;
            this.loadValues(JSON.parse(data), true);
          }
          this.loading = false;
        }
      } catch (e) {
        console.log(e.message);
        this.loading = false;
        alertS.error('Error loading search');
      }
    },
    loadValues(leaks, noAlert) {
      const { found, foundWithPass, results } = leaks;
      if (!noAlert) {
        this.$swal.close();
        // alertS.error(`Se han encontrado ${found} brechas<br>${foundWithPass} de ellas con contraseñas expuestas`);
      }
      this.values = results.map((el) => {
        /** Setup Last Breach Date */
        if (el && el.lastBreach && el.lastBreach.BreachDate) el.lastBreachD = el.lastBreach.BreachDate;
        else el.lastBreachD = new Date('10-10-1970');

        const arrLine = el.line.split(':');
        el.email = arrLine[0].toLowerCase();
        el.password = arrLine[1];
        el.combo = `${arrLine[0]}:${arrLine[1]}`;

        el.searched = false;
        if (this.$route.query.password && el.password) {
          const lwQ = this.$route.query.password.toLowerCase();
          const lwP = el.password.toLowerCase();
          if (lwP.includes(lwQ) || lwQ.includes(lwP)) {
            el.searched = true;
          }
        }

        return el;
      });
      this.allValues = Object.assign(this.values);
      this.loadBreaches();
    },
    async getSettings() {
      if (!this.userInfo.unique_id) return;

      const isPremium = this.isPremiumUser;
      if (!isPremium) {
        this.disable_settings = true;
        this.sendDiscord = false;
        this.sendTelegram = false;
        this.sendEmail = false;
        this.saveEntry = false;
        return;
      }

      this.disable_settings = true;
      const settings = await rService.post(`get_settings`, { table: 'settings_1' }, true, false);
      if (settings) {
        console.log('Settings', settings);
        this.sendDiscord = settings.sendDiscord;
        this.sendTelegram = settings.sendTelegram;
        this.sendEmail = settings.sendEmail;
        this.saveEntry = settings.saveEntry;

        if (this.sendDiscord) {
          this.sendDiscordUpdate();
        }
        if (this.sendTelegram) {
          this.sendTelegramUpdate();
        }
      }
      this.disable_settings = false;
    },
    async saveSettings() {
      if (!this.userInfo.unique_id) return;
      this.disable_settings = true;
      await rService.post(
        `save_settings`,
        {
          table: 'settings_1',
          sendDiscord: this.sendDiscord,
          sendTelegram: this.sendTelegram,
          sendEmail: this.sendEmail,
          saveEntry: this.saveEntry,
        },
        true,
        false
      );
      this.disable_settings = false;
    },
    async checkBreaches() {
      this.cleanLastResult();
      this.values = [];
      this.breaches = [];
      const postBody = {
        email: this.toSearch,
        type: this.typeSelected,
        sendDiscord: this.sendDiscord,
        sendTelegram: this.sendTelegram,
        sendEmail: this.sendEmail,
        hideEntry: !this.saveEntry,
      };
      this.typeSelP = `${this.typeSelected}`;
      this.lastSearch = `${this.toSearch}`;
      this.loading = true;

      alertS.loading(this.translate('checkingForLeaks'));
      const leaks = await rService.post(`check/${this.userInfo.platform}`, postBody, false, true);
      this.setupTimer();
      this.updateUserInfo();
      if (leaks && leaks.discordRes) {
        if (leaks.discordRes.error) {
          this.discordError = `Discord Error: ${leaks.discordRes.error}`;
        }
      }
      if (leaks && leaks.telegramRes) {
        if (leaks.telegramRes.error) {
          this.telegramError = `Telegram Error: ${leaks.telegramRes.error}`;
        }
      }
      if (leaks.error) {
        const error = leaks.error;
        if (error == 'No user') {
          leaks.error = 'User session expired..';
          this.destroyUserInfo();
        }
        this.loading = false;
        return alertS.error(leaks.error);
      }
      if (leaks.success) {
        alertS.success(this.translate('success'));
        this.values = [];
        this.allValues = [];
        this.safe = true;
        this.colorCard = 'safe';
      } else {
        this.safe = false;
        this.colorCard = 'unsafe';
        this.loadValues(leaks, false);
      }
      this.loading = false;
      this.setCooldown({ status: true, resetTimeLeft: true });
    },
    cleanSearch() {
      // this.toSearch = '';
      const searchField: any = document.querySelector('.searchFieldCol .mdi-close');
      if (searchField) {
        try {
          searchField.click();
        } catch (e) {
          console.error(e);
        }
      }
    },
    searchNow() {
      this.values = this.allValues.filter((el) => {
        return this.customFilter(null, null, el);
      });
    },
    checkType(type) {
      if (type != this.lastType) {
        this.cleanSearch();
        const placeHolderType = this.placeHolderLink[type];
        if (!placeHolderType) {
          this.searchHolder = this.$vuetify.lang.t('$vuetify.Home.searchHolder');
          this.searchDisabled = true;
          this.disableSearchBtn = true;
        } else {
          this.searchHolder = `${this.$vuetify.lang.t('$vuetify.Home.enter')} ${placeHolderType}`;
          this.searchDisabled = false;
          this.disableSearchBtn = false;
        }
        this.lastType = type;
      }
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    getRules() {
      return getRules(this.translate);
    },
    download(sources) {
      let text = '';
      let title = '';
      if (sources) {
        title = `${this.lastSearch} with sources ${this.$moment().format('YYYY-MM-DD')}`;
        text = this.filtered
          .map((el) => {
            return `${el.combo} [${el.sources
              .map((source) => {
                return source.Name;
              })
              .join(',')}]`;
          })
          .join('\n');
      } else {
        title = `${this.lastSearch} ${this.$moment().format('YYYY-MM-DD')}`;
        text = this.filtered
          .map((el) => {
            return el.combo;
          })
          .join('\n');
      }
      const FileSaver = require('file-saver');
      const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, `${title}.txt`);
    },
  },
  computed: {
    isPremiumUser() {
      return this.userInfo && ['pro', 'plus'].includes(this.userInfo.plan);
    },
    dehashedDisabled() {
      return !this.validFormSearch || this.disableSearchBtn || this.limitReached || this.activeCooldown;
    },
    isUserResult() {
      const userKeys = ['login', 'pass_login'];
      return userKeys.includes(this.typeSelP);
    },
    maxHeightSourcesMenu() {
      if (this.windowWidth < 768) return 150;
      return 304;
    },
    types() {
      try {
        const allowedTypes = this.timerInfo.allowedTypes;
        if (allowedTypes[0] == 'all') return this.getTypes();
        if (allowedTypes[0] == '0') throw new Error('Not allowed types');

        return this.getTypes().map(function (el) {
          const { value } = el;
          if (!allowedTypes.includes(value)) el.disabled = true;
          return el;
        });
      } catch (e) {
        return this.getTypes().map(function (el) {
          el.disabled = true;
          return el;
        });
      }
    },
    headers() {
      const isHashSearch = this.typeSelP == 'hash';
      const passHeader = isHashSearch ? 'Hash' : this.translate('headers.password');
      const emailHeaders = this.isUserResult ? this.translate('headers.user') : this.translate('headers.email');
      const lastBreachHeader = this.translate('headers.lastBreach');
      const toReturn = [
        { text: emailHeaders, value: 'email', width: '300px' },
        { text: passHeader, value: 'password', width: '300px' },
        { text: lastBreachHeader, value: 'lastBreachD', width: '150px' },
        { text: this.translate('headers.sources'), value: 'sources', width: '300px' },
      ];
      if (!isHashSearch) {
        toReturn.push({ text: this.translate('headers.dehashed'), value: 'dehashed', width: '150px' });
      }
      return toReturn;
    },
    propsSearchItem() {
      return {
        findLine: this.findLine,
        sourceTypes: this.sourceTypes,
        breachesFound: this.breachesFound,
        searchItem: this.searchItem,
      };
    },
    rules: function () {
      const { generalRules, ruleTypes } = this.getRules();
      const extra = ruleTypes[this.typeSelected];
      if (extra) {
        return [...generalRules, ...extra];
      } else {
        return [];
      }
    },
    placeHolderLink: function () {
      const t = this.translate;
      return {
        email: t('placeHolderLink.email'),
        mass: t('placeHolderLink.mass'),
        login: t('placeHolderLink.login'),
        pass_email: t('placeHolderLink.pass_email'),
        domain_email: t('placeHolderLink.domain_email'),
        phone: t('placeHolderLink.phone'),
        pass_phone: t('placeHolderLink.pass_phone'),
        mc: t('placeHolderLink.mc'),
        pass_mc: t('placeHolderLink.pass_mc'),
        pass_login: t('placeHolderLink.pass_login'),
        hash: t('placeHolderLink.hash'),
      };
    },
    ...mapGetters([
      'activeCooldown',
      'userInfo',
      'loggedIn',
      'timerInfo',
      'limitReached',
      'limitReachedAbs',

      // ...
    ]),
    ...mapGetters({
      connected: 'socket/connected',
    }),
    overlay() {
      return !(this.userInfo.plan && this.userInfo.plan != 'unverified');
    },
  },
  mounted() {
    this.getSettings();
    this.loadSearch();
    this.$socket.emit('start_connection', true);
  },
  beforeMount() {},
  beforeDestroy() {
    this.$store.commit('updateSafe', null);
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == 'updateCheckInput') {
        this.typeSelected = state.toCheck.type;
        this.toSearch = state.toCheck.info;
      }
    });
  },
  watch: {
    '$route.params.id'(newVal) {
      if (!newVal) {
        this.loadSearch();
      }
    },
    safe(newVal) {
      this.$store.commit('updateSafe', this.safe);
    },
    'userInfo.unique_id'(newVal) {
      const router = this.$route;
      if (router.params.id) {
        this.entrySearch = router.params.entrySearch;
        this.loadFromId(router.params.id);
      }
      this.getSettings();
      if (newVal) this.sendUpdates();
    },
    propsSearchItem: {
      handler(val) {
        if (this.instantFilter) this.searchNow();
      },
      deep: true,
    },
  },
};
